import en from 'vee-validate/dist/locale/en.json'
export default (context) => {
  return new Promise(function (resolve) {
    resolve({
      meta: {
        title: 'Bilietai į koncertus, spektaklius ir kitus renginius'
      },
      validation: {
        ...en.messages,
        phone: 'Šiame lauke turi būti galiojantis telefono numeris',
        password: 'Slaptažodžiai nesutampa',
        payment_method: 'Prašome pasirinkti pageidaujamą mokėjimo būdą'
      },
      errors: {
        basket: {
          ticket_cant_be_reserved: 'Šį bilietą kažkas jau rezervavo'
        },
        checkout: {
          email_invalid: 'Email is invalid',
          email_domain_invalid: 'Email domain is invalid',
          surname_too_short: 'Surname is too short',
          name_too_short: 'Name is too short'
        },
        promo_code_not_found: 'Nuolaidos kodo nepavyko pritaikyti'
      },
      cookies: {
        content: `Ši svetainė naudoja slapukus, kurie yra absoliučiai būtini,
        kad ji tinkamai veiktų. Norėdami sužinoti daugiau, peržiūrėkite
        mūsų slapukų politika. Jei naudojimo metu reikiamų slapukų nėra,
        yra galimybė, kad kai kurios paslaugos ar svetainės
        funkcijos gali neveikti. Norėdami pagerinti vartotojo patirtį ir
        paslaugų kokybę, svetainėje naudojami šių tipų slapukai:
        analitiniai slapukai, kurie analizuoja ir gerina svetainę, socialinių
        tinklų slapukai, leidžiantys dalytis turiniu socialiniuose tinkluose ir
        taip pat rinkodaros slapukus.`,
        title: `Toliau naudodamiesi svetaine, nurodykite, ar sutinkate
        dėl slapukų naudojimo`,
        required: 'Privalomi slapukai',
        statistics: 'Statistikos slapukai',
        social: `Socialinių tinklų slapukai (Patikrinkite, ar
          norite bendrinti puslapio turinį socialiniuose tinkluose)`,
        marketing: 'Rinkodaros slapukai'
      },
      password: {
        weak: 'Silpnas',
        fair: 'Vidutinis',
        good: 'Geras',
        strong: 'Stiprus',
        excellent: 'Labai stiprus'
      },
      menuTitles: {
        concerts: 'Koncertai',
        theater: 'Teatras',
        festivals: 'Festivaliai'
      },
      buttons: {
        save: 'Išsaugoti',
        change_password: 'Keisti slaptažodį',
        sign_in: 'Prisijungti',
        sign_up: 'Registruotis',
        load_more: 'Įkelti daugiau',
        retry_purchase: 'Bandyti pirkti dar kartą',
        fix_world: 'Pabandyk sutvarkyti pasaulį',
        float_away: 'Nuplaukti',
        apply: 'Taikyti',
        clear: 'Išvalyti',
        read_more: 'Daugiau informacijos',
        tickets_and_more: 'Bilietai ir daugiau',
        enter: 'Įveskite'
      },
      modal: {
        cookies_updated: 'Jūsų slapukai atnaujinti!',
        request_submitted: 'Jūsų užklausa buvo sėkmingai pateikta',
        password_changed: 'Slaptažodis pakeistas!',
        personal_data_updated: 'Asmeniniai duomenys atnaujinti!'
      },
      search: {
        search: 'Ieškoti',
        placeholder: 'Ieškoti renginių, vietų',
        view_all_results: 'Rodyti viską',
        no_results_found: 'Rezultatų nerasta'
      },
      headings: {
        presale_code: 'Išankstinio pardavimo kodas...',
        about: 'Apie',
        success: 'Sėkmė!',
        sign_in: 'Prisijungti',
        sign_in_with: 'Prisijungti naudojant',
        sign_up_with: 'Registruotis su',
        password_change: 'Slaptažodžio keitimas',
        recommended: 'Rekomenduojama',
        related: 'Susiję įvykiai',
        latest: 'Naujausia',
        news: 'Naujienos',
        rescheduled: 'Perkeltas',
        cancelled: 'Atšaukta',
        username: 'Vartotojo vardas',
        old_password: 'Senas slaptažodis',
        new_password: 'Naujas slaptažodis',
        repeat_password: 'Pakartokite slaptažodį',
        password: 'Slaptažodis',
        forgotpass: 'Pamiršau slaptažodį',
        iagree: 'Sutinku',
        name: 'Vardas',
        surname: 'Pavardė',
        email: 'El. paštas',
        phone: 'Telefonas',
        or: 'arba',
        newuser: 'Naujas vartotojas',
        userhaveaccount: 'Vartotojas jau turi paskyrą',
        customersupport: 'Klientų aptarnavimas',
        payment: 'Mokėjimas',
        unsubscribe: 'Unsubscribe',
        unsubscribe_subtitile:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        reset_password: 'Slaptažodžio atnaujinimas',
        reset_password_l2:
          'Nesijaudinkite. El. paštu atsiųsime instrukcijas, kaip iš naujo nustatyti slaptažodį.',
        categories: 'Kategorijos',
        events: 'Renginiai',
        logging_out: 'Atsijungimas',
        payment_failed: 'Mokėjimas nepavyko',
        payment_success: 'Mokėjimas sėkmingai atliktas!',
        search_results_for: 'Paieškos rezultatai'
      },
      badges: {
        soldout: 'Išparduota',
        premiere: 'Premjera'
      },
      navigation: {
        information: 'Informacija',
        faq: 'DUK',
        terms: 'Bilietų pirkimo taisyklės',
        tickets: 'Bilietai',
        etickets: 'E-bilietai',
        payments: 'Mokėjimai',
        privacy: 'Slapukų naudojimo politika',
        cookies: 'Cookies usage policy',
        contacts: 'Kontaktai',
        news: 'Naujienos',
        partners: 'Partneriai',
        about: 'Apie mus',
        authorize: 'Prisijungti',
        register: 'Registruotis',
        userprofile: 'Profilis',
        pay: 'Mokėti',
        apply: 'Taikyti',
        logout: 'Išeiti',
        usertickets: 'Mano bilietai',
        backToTickets: 'Atgal į bilietus',
        backToEventInfo: 'Atgal į renginio informaciją',
        back: 'Back',
        gotopayment: 'Eiti į mokėjimą',
        retry_trans: 'Bandyti operaciją dar kartą',
        closeCart: 'Uždaryti krepšelį',
        remove: 'Pašalinti',
        insurance: 'Draudimo sąlygos',
        publiccontract: 'Bendrosios bilietų pardavimo sutarties sąlygos'
      },
      labels: {
        company: 'Įmonės pavadinimas',
        email: 'El. paštas',
        confirm_email: 'Confirm E-mail',
        legalAddress: 'Juridinis adresas',
        name: 'Vardas',
        optional: '(neprivaloma)',
        phone: 'Telefonas',
        regnr: 'Reg. Nr.',
        required: '(privaloma)',
        surname: 'Pavardė',
        vat: 'PVM Nr.'
      },
      misc: {
        support_email: 'Support email',
        tickets_left: 'Tickets available',
        choose_time: 'Choose time',
        select_your_branch: 'Select Your branch',
        back_to_all_payment_methods: 'Back to all payment methods',
        accept: 'Priimti',
        additionalOptions: 'Papildomi nustatymai',
        langselector: 'Lietuvių',
        withuseragreement: 'sąlygos ir sąlygos',
        price_from: 'nuo',
        from: 'nuo',
        to: 'iki',
        price: 'kaina',
        soldout: 'išparduota',
        discount: 'Nuolaida',
        download: 'Atsisiųsti',
        event_name: 'Renginio pavadinimas',
        loading: 'įkeliama',
        processing_order: 'Užsakymas pdorojamas',
        load_more: 'Įkelti daugiau',
        more_info: 'Daugiau informacijos',
        selectDiscount: 'Pasirinkite nuolaidą',
        sevice_fee_and_vat: 'Paslaugos mokestis (su PVM)',
        fixed_service_fee: 'Venue service fee (incl. VAT)',
        ticket_details: 'Bilieto informacija',
        ticket_id: 'Bilieto ID',
        ticket_no: 'Bilieto Nr',
        ticket_type: 'Bilieto tipas',
        tickets: 'bilietai | bilietas | bilietai',
        total: 'Iš viso',
        buy_ticket: 'Pirkti bilietą',
        add_to_cart: 'Add to cart',
        standticket: 'Stovimi bilietai',
        seatticket: 'Sėdimi bilietai',
        close: 'Uždaryti',
        required_fields: 'Privalomi laukai',
        at: '',
        no_tickets: 'Bilietų nėra',
        order_id: 'Užsakymo ID',
        purchased: 'Pirktas',
        download_invoice: 'Atsisiųsti sąskaitą faktūrą',
        download_pdf: 'Atsisiųsti PDF',
        not_available: 'nepasiekiama',
        work_hours: 'P. - S.',
        doors: 'Durys',
        start: 'Pradžia',
        age_restriction: 'Amžiaus apribojimas',
        tags: 'Žymės',
        by: 'pagal',
        organised_by: 'Organizatorius',
        sector: 'SEK'
      },
      ticketselector: {
        SALES_NOT_STARTED: {
          title: 'Pardavimai dar neprasidėjo',
          subtitle: ''
        },
        SALES_ENDED: {
          title: 'Pardavimai baigėsi',
          subtitle: ''
        },
        AVAILABLE: {
          title: '',
          subtitle: ''
        },
        SOMEONE_IS_BUYING: {
          title: 'Kažkas perka paskutinius bilietus',
          subtitle: 'Galbūt keli bilietai bus išleisti po kelių minučių'
        },
        OWN: {
          title: '',
          subtitle: ''
        },
        SOLDOUT: {
          title: 'PARDUOTA',
          subtitle: ''
        }
      },
      text: {
        password_reset_email_sent:
          'Instrukcijos dėl slaptažodžio keitimo buvo išsiųstos į nurodytą el. pašto adresą.',
        password_has_been_changed: 'Jūsų slaptažodis buvo sėkmingai pakeistas!',
        unsubscribed: 'You successfully unsubscribed!',
        agreeOnTerms: {
          text: 'Perskaičiau ir sutinku su {link}!',
          link: 'el. parduotuvės naudojimo ir bilietų draudimo (p.5.7.) taisyklėmis ir sąlygomis'
        },
        cart_is_empty: 'Krepšelis tuščias',
        enter_promo_code: 'Įveskite nuolaidos kodą',
        stripeDescription:
          'Turite 10 minučių mokėjimui atlikti. Priešingu atveju sandoris bus atšauktas ir jūsų rezervuoti bilietai taps laisvai prieinami.',
        fdataDescription:
          'Turite 10 minučių mokėjimui atlikti. Priešingu atveju sandoris bus atšauktas ir jūsų rezervuoti bilietai taps laisvai prieinami.',
        i_have_promocode: 'Turiu nuolaidos kodą',
        promocode_activated: 'Reklamos kodas aktyvuotas!',
        promocode_apply_fail: 'Nuolaidos kodo nepavyko pritaikyti',
        newsAndOffers: 'Ar norite gauti naujienų ir puikių bilietų pasiūlymų?',
        purchaseAsLegal: 'Pirkimas kaip juridinio asmuo',
        swedDescription:
          '<b>Šis mokėjimo tipas galimas tik „Swedbank“ Latvijoje klientams.</b> Turite 10 minučių atlikti mokėjimą. Priešingu atveju sandoris bus atšauktas ir jūsų rezervuoti bilietai taps laisvai prieinami.',
        payment_failed_w_error: 'Įvyko klaida. Mokėjimas nepavyko.',
        invoice_copy_sent:
          'Bilietų ir sąskaitos faktūros kopija išsiųsta jūsų el. pašto adresu:',
        invoice_copy_will_be_sent:
          'Netrukus bilietai bus išsiųsti į Jūsų nurodytą el. paštą.',
        user_exists_1: 'Vartotojas su šiuo el. pašto adresu jau yra ',
        user_exists_2: 'prisijungti',
        user_exists_3: ' arba ',
        user_exists_4: 'atkurti slaptažodį',
        something_wrong: 'Kažkas tikrai ne taip',
        page_not_found: 'Puslapis nerastas',
        no_events_available: 'Renginių nėra',
        order_completed_1: 'Jūsų užsakymas',
        order_completed_2: 'už',
        order_completed_3: 'baigta',
        processing_tickets: 'Apdorojami bilietai',
        choose_payment_country:
          'Pasirinkite, kurios šalies bankus / mokėjimo sistemas naudoti.'
      },
      titles: {
        agreement: 'Sutartis',
        buyer: 'Pirkėjas',
        paymentMethod: 'Mokėjimo būdas',
        purchased_tickets: 'Atsisiųskite savo bilietus',
        your_tickets: 'Jūsų bilietai'
      },
      filters: {
        all_dates: 'Visos datos',
        all_categories: 'Visos kategorijos',
        category_selected: 'pasirinkta kategorija',
        pick_category: 'Pasirinkite kategoriją',
        all_locations: 'Visos vietos',
        pick_country: 'Select country',
        location_selected: 'vieta pasirinkta',
        pick_location: 'Pasirinkite vietą',
        pick_another_date: 'Pasirinkite kitą datą'
      },
      fdata: {
        100: 'Atmesta (bendrai, be komentarų)',
        101: 'Atmesta, kortelės galiojimas pasibaigęs',
        102: 'Atmesta, įtariamas sukčiavimas',
        104: 'Atmesta, apribota kortelė',
        105: 'Atmesta, kortelės priėmėjas skambina gavėjo apsaugos skyriui',
        106: 'Atmesta, viršytas leistinas PIN įvedimo bandymas',
        111: 'Atmesta, neteisingas kortelės numeris',
        116: 'Atmesta, nėra kortelės duomenų',
        118: 'Atmesta, nepakanka lėšų',
        119: 'Atmesta, operacija neleidžiama kortelės turėtojui',
        120: 'Atmesta, operacija neleidžiama į terminalą',
        121: 'Atmesta, viršijama išėmimo sumos limitą',
        122: 'Atmesta, saugumo pažeidimas',
        123: 'Atmesta, viršijama išėmimo dažnumo ribą',
        129: 'Atmesta, įtariama, kad kortelė padirbta',
        204: 'Paėmimas, ribota kortelė',
        208: 'Paėmimas, pamesta kortelė',
        902: 'Atmetimo priežasties pranešimas: netinkama operacija',
        904: 'Atmetimo priežasties pranešimas: formatavimo klaida',
        908: 'Atmetimo priežasties pranešimas: nepavyko rasti operacijos paskirties vietos',
        909: 'Atmetimo priežasties pranešimas: sistemos gedimas',
        911: 'Atmetimo priežasties pranešimas: baigėsi kortelės išdavėjo skirtasis laikas',
        912: 'Atmetimo priežasties pranešimas: kortelės išdavėjas nepasiekiamas',
        940: 'Atmesta, užblokuota sukčiavimo filtro'
      },
      eventLabels: {
        postponed: 'Nauja data jau greitai!',
        suspended: 'Šio renginio pardavimas sustabdytas iki būsimo pranešimo.',
        preorder:
          'Išankstinis pardavimas pradžia <b class="color--text-blue wsnw">{from}</b>',
        public_preorder:
          'Bilietai prekyboje nuo <b class="color--text-blue wsnw">{from}</b>'
      },
      eventLabelsButton: {
        preorder: 'Pirk po {0}',
        onlinesaleended: 'Prekyba internetu uždaryta'
      },
      presale: {
        early_access: 'Išankstinė prieiga išankstinis pardavimas',
        early_access_tooltip:
          'Jei turite specialų išankstinio pardavimo kodą, galite įsigyti bilietus į šį renginį prieš prasidedant viešam išpardavimui. Išankstinio pardavimo kodus platina atlikėjas ir/ar renginio organizatorius. Ticketshop šių kodų neturi.'
      },
      countdown: {
        days: 'd.',
        hours: 'v.',
        minutes: 'min.',
        seconds: 's.'
      },
      insurance: {
        available: 'Galimas bilietų draudimas',
        tickets: 'Bilietų draudimas',
        title: 'BŪK DROŠAS IR IZVĒLIES BIĻEŠU APDROŠINĀŠANU!',
        description:
          'Nuostoliai bus padengti, jei pateksite į nelaimingą atsitikimą ir negalėsite dalyvauti renginyje dėl sveikatos apribojimų arba renginio atšaukimo/atidėjimo atveju ir nauji pakeitimai Jums netinka.',
        details: 'Daugiau informacijos apie draudimo sąlygas',
        secure_tickets: 'Apdrausti bilietus',
        continue_without: 'Tęskite be draudimo',
        cost: 'DRAUDIMO MOKESTIS',
        secure_order: 'Apdrauskite savo pirkinį',
        cancel: 'Atšaukti draudimą',
        ticket_secured: 'Bilietas apdraustas',
        ticket_unavailable: 'Draudimas neprieinamas',
        provider: 'BALCIA DRAUDIMAS',
        info: {
          title: 'Mes padengsime nuostolius, jei:',
          list: [
            'Turėjote problemų ir neinate į renginį;',
            'Covid-19 ar kiti sveikatos apribojimai neleidžia planuoti dalyvauti renginyje;',
            'Renginys bus atšauktas arba atidėtas, o laikas ar vieta jums netinka.'
          ]
        }
      },
      currencyModal: {
        title: 'TURITE NEBAIGTĄ PIRKIMĄ {cartCurrency} VALIUTOJE',
        subtitle:
          'Renginys, į kurį norite pasirinkti bilietus, yra {eventCurrency} valiutoje. Šiuo metu jūsų krepšelyje yra bilietai, kurių kaina nustatyta {cartCurrency} valiutoje. Turite užbaigti esamą pirkimą arba išvalyti krepšelį, kad galėtumėte atlikti pirkimą {eventCurrency} valiutoje.',
        finish_payment: 'Užbaigti esamą pirkimą {cartCurrency} valiutoje',
        clear_cart:
          'Išvalyti krepšelį ir atlikti naują pirkimą {eventCurrency} valiutoje'
      },
      eventSubscribeModal: {
        title: 'Registracija'
      },
      eventSubscribe: {
        registration: 'Registracija',
        register: 'Užsiregistruok',
        earlyBird:
          'Registruokitės ir gaukite galimybę įsigyti ribotus <b class="color--text-blue wsnw">Early bird bilietus</b>'
      },
      refund: {
        title: 'GRĄŽINIMAS už pirkimą Nr. <div>{number}</div>',
        details: 'Grąžinimo informacija',
        deadline: 'Grąžinimo galima prašyti iki:',
        reason: 'Grąžinimo priežastis',
        info: 'Atkreipkite dėmesį, kad grąžinimas vykdomas į tą pačią banko sąskaitą ar mokėjimo kortelę, iš kurios buvo atliktas pradinis pirkimas.',
        payment: {
          type: 'Mokėjimo būdas',
          bank: 'Banko pavadinimas',
          payer: 'Gavėjo vardas',
          account: 'Sąskaitos nr.'
        },
        order_tickets: 'Bilietų pirkimas',
        ticket_number: 'Bilieto nr.',
        ticket_type: 'Bilieto tipas',
        ticket_price: 'Kaina',
        ticket_venue: 'Renginio vietos mokestis',
        ticket_service: 'Paslaugos mokestis',
        flat_price: 'Bilietų grynoji vertė (įsk. PVM)',
        total_refund: 'Iš viso grąžinti',
        initiate: 'Prašyti grąžinimo',
        terms: 'Susipažinau ir sutinku su naudojimosi sąlygomis.',
        steps: {
          initiate: 'Grąžinimas inicijuotas',
          process: 'Grąžinimas apdorojamas',
          compete: 'Grąžinimas baigtas'
        },
        success: {
          title: 'Grąžinimo prašymas sėkmingai pateiktas.',
          subtitle:
            'Jūsų grąžinimo prašymas bus netrukus apdorotas. Kai grąžinimas bus atliktas, gausite atskirą el. laišką.'
        },
        payout: {
          title: 'Grąžinimas vykdomas',
          subtitle:
            'Jūsų grąžinimo prašymas bus netrukus apdorotas. Kai grąžinimas bus atliktas, gausite atskirą el. laišką.'
        },
        completed: {
          title: 'Grąžinimas baigtas',
          subtitle:
            'Jūsų grąžinimas buvo apdorotas. Prašome peržiūrėti grąžinimo el. laišką dėl detalių.'
        },
        expired: {
          title: 'Prašymų grąžinti pinigus pateikimo terminas pasibaigė',
          subtitle:
            'Šiuo metu bilietų pardavėjui neleidžiama grąžinti pinigų už šį pirkimą, nes praėjo nurodytas dienų skaičius.'
        }
      }
    })
  })
}
